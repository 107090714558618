<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="B2C 🚀">
      <b-card-text>Statistique de livraison</b-card-text>
    </b-card>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-container fluid>
      <h2>
        Suivi des Commandes par Gouvernorat
      </h2>
      <div>
        <h2 class="mb-4">
          Explorez les Performances Mensuelles des Gouvernorats
        </h2>
        <b-table
          :items="governoratesData"
          :fields="fields"
          class="mb-4"
        >
          <template #cell(percentage)="data">
            {{ data.value }}%
          </template>
        </b-table>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BCard, BCardText,
  BSpinner,
} from 'bootstrap-vue'

import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BCardText,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      user: {},
      governoratesData: [],
      fields: [
        { key: 'governorate__name', label: 'Gouvernorat' },
        { key: 'orders_count', label: 'Nombre de commandes' },
        { key: 'percentage', label: 'Pourcentage' },
      ],
      isFreeTrial: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }
  },
  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'admin') {
      this.fetchGovernoratesData()
    }
  },
  methods: {
    async fetchGovernoratesData() {
      try {
        this.isLoading = true
        const { data } = await axios.get(`/api/kpi/governorates/?year=${this.year}&month=${this.month}`)
        this.governoratesData = data
        this.isLoading = false
      } catch (error) {
        console.error('Erreur lors de la récupération des données des gouvernorats :', error)
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('fr-FR', options)
    },
    show(level) {
      this.$router.push({ name: 'order-list', params: { level } })
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
  },
}
</script>
  <style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-good-table.scss';

  .b-card {
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    margin-bottom: 1rem;
    background-color: #fff;
    padding: 1rem;
  }

  .b-badge {
    font-size: 14px;
  }
  </style>
